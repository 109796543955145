/* eslint-disable @typescript-eslint/naming-convention */
import { Component, InjectionToken, Injector, Optional } from '@angular/core';
import { MenuComponent } from './menu.component';

export type BasicMenuInsertComponentType<T = any> = new (...args: any[]) => BasicMenuInsertComponent<T>;

export const BASIC_MENU_INSERT_INJECTION_TOKEN = new InjectionToken<any>('BASIC_MENU_INSERT_INJECTION_TOKEN');

@Component({
    template: ''
})
export abstract class BasicMenuInsertComponent<T = any> {

  private _data: T;
  private _menuRef: MenuComponent;

  get injectedData(): T {
    return this._data;
  }

  get menuRef(): MenuComponent {
    return this._menuRef;
  }

  constructor(@Optional() injector: Injector) {
      try {
          this._data = injector.get(BASIC_MENU_INSERT_INJECTION_TOKEN);
          this._menuRef = injector.get(MenuComponent);
      } catch (_) {
          // ignore exception
      }
  }

}
