/* eslint-disable @angular-eslint/component-max-inline-declarations */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Injector, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { convertCSSUnitInPixel } from '../../functions/numbers';
import { BasicMenuInsertComponentType, BASIC_MENU_INSERT_INJECTION_TOKEN } from './basic-menu-insert.component';


export interface MenuInsert<T = any> {
  data?: T;
  component?: BasicMenuInsertComponentType;
  /**
   * set and used by the component
  */
  __injector?: Injector;
}

export interface MenuComponentOptions<T = any> {
  inserts: MenuInsert<T>[];
  /**
   * whether component won't check if the menu is within the horizontal line of the viewport and
   * corrects it by translating it
   * default: false
   */
  noAutomaticTranslations?: boolean;
  /**
   * menu is able to receive a specific z-index
   * default: 1
   */
  zIndex?: number;
  /**
   * css value for the osition of the menu's container
   * default: relative
   */
  menuCSSPosition?: 'relative' | 'absolute';
  /**
   * use 'inline' if the menu should demand its needed space in the DOM
   * for example: necessary if it is in another MenuComponent and it needs to trigger its resize detection
   * default: 'block'
   */
  menuCSSDisplay?: 'block' | 'inline';
  /**
   * whether menu pops up or slides down
   * default: false
   */
  popup?: boolean;
  /**
   * whether an outside click closes the menu or not: default: false
   */
  closesOnOutsideClick?: boolean;
  /**
   * extra pixel offset of the menu window in the vertical direction
  */
  verticalMenuOffset?: number;
  /**
   * extra pixel offset of the menu window in the horizontal direction
   */
  horizontalMenuOffset?: number;
  /**
   * if this function returns null or undefined then internal calculations are default
   */
  getMenuMaxHeight?: () => string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  exportAs: 'app-menu',
  changeDetection: ChangeDetectionStrategy.Default
})
export class MenuComponent implements OnInit, OnDestroy {

  private isOpenBehaviorSubject = new BehaviorSubject<boolean>(false);
  private _options: MenuComponentOptions;

  private _fullWidth = false;

  private resizeObserver = new ResizeObserver(_ => {
    if (this.isOpen) {
      this.updateContainerHeight();
    }
  });

  private contentRect: DOMRect;

  @HostBinding('class')
  get hostClasses(): string[] {
    const arr = ['app-menu'];
    if (this._fullWidth) {
      arr.push('app-menu-full-width');
    }
    return arr;
  }

  get containerClasses(): string[] {
    const arr = ['app-menu-container'];
    arr.push(this.isOpen ? 'app-menu-container-is-open' : 'app-menu-container-is-closed');
    if (this.options?.popup) {
      arr.push('popup-menu');
    }
    if (this.options?.menuCSSDisplay === 'inline') {
      arr.push('menu-inline');
    }
    return arr;
  }

  @Input()
  set options(value: MenuComponentOptions) {
    this._options = value;
    if (value?.inserts) {
      value.inserts?.forEach((insert, i) => {
        if (!insert.__injector) {
          insert.__injector = Injector.create({
            name: 'MenuInsert_' + (i),
            parent: this.injector,
            providers: [
              {provide: BASIC_MENU_INSERT_INJECTION_TOKEN, useValue: insert.data},
              {provide: MenuComponent, useValue: this}
            ]
          });
        }
      });
    }
    if (typeof value?.zIndex === 'number') {
      this.setZIndex(value.zIndex);
    }
    if (typeof value?.menuCSSPosition === 'string') {
      this.setMenuPosition(value.menuCSSPosition);
    }
    if (typeof value?.menuCSSDisplay === 'string') {
      this.setMenuDisplay(value.menuCSSDisplay);
    }
  }

  get options(): MenuComponentOptions {
    return this._options;
  }

  @Input()
  elementsPartOfMenu?: HTMLElement[];

  get open$(): Observable<boolean> {
    return this.isOpenBehaviorSubject.asObservable();
  }

  get isOpen(): boolean {
    return this.isOpenBehaviorSubject?.value;
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly windowRef: WindowRef,
    private readonly injector: Injector,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const host = this.getHostElement();
    const contentElement = host.querySelector('.app-menu-content');
    this.resizeObserver.observe(contentElement);
    this.updateContainerHeight();

  }


  ngOnDestroy() {
    this.resizeObserver?.disconnect();
  }


  open() {
    if (this.isOpen) {
      return;
    }

    this.isOpenBehaviorSubject.next(true);
    this.updateContainerHeight();
    this.cdr.detectChanges();

    if (this.options?.closesOnOutsideClick) {
      this.windowRef.nativeWindow.setTimeout(() => {
        this.windowRef.nativeWindow.addEventListener('click', this.closesOnOutsideClickHandler);
      }, 0);
    }
  }


  close() {
    if (!this.isOpen) {
      return;
    }
    this.isOpenBehaviorSubject.next(false);
    this.cdr.detectChanges();
    this.getHostElement().classList.remove('app-menu-full-width');
    this.windowRef.nativeWindow.removeEventListener('click', this.closesOnOutsideClickHandler);
  }


  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  getHostElement(): HTMLElement {
    const hostEl = this.elementRef?.nativeElement as HTMLElement;
    if (!hostEl) {
      console.warn('was not able to receive nativeElement of the menu');
    }
    return hostEl;
  }

  triggerMarkForChange() {
    this.cdr.detectChanges();
  }

  private closesOnOutsideClickHandler = (event: PointerEvent) => {

    let isOutsideClick = false;
    const contentElement = this.getHostElement().querySelector('.app-menu-content');
    const path = event.composedPath();
    isOutsideClick = !path.includes(contentElement);

    // detect if an outside element, which is still considered as part of the menu, was clicked
    // if so this would be considered as an inside click
    this.elementsPartOfMenu?.forEach(el => {
      if (isOutsideClick) {
        const isInsidePartOfMenuEl = path.includes(el);
        isOutsideClick = isInsidePartOfMenuEl;
      }
    });

    if (isOutsideClick) {
      this.close();
    }

  };

  private updateContainerHeight() {

    const customMaxHeight = this.options?.getMenuMaxHeight?.();

    const contentElement = this.getHostElement().querySelector('.app-menu-content');
    this.contentRect = contentElement?.getBoundingClientRect();

    const height = !customMaxHeight ? ((this.contentRect?.height ?? 1000) + 'px') : customMaxHeight;
    this.setMaxHeight(height);

    if (!this.options?.noAutomaticTranslations) {
      const orignRect = this.getHostElement()?.getBoundingClientRect();
      const style = this.windowRef.nativeWindow?.getComputedStyle(this.getHostElement());
      this.updateTranslations(orignRect, this.contentRect, style);
    }
  }

  private setMaxHeight(cssValue: string) {
    this.getHostElement()?.style?.setProperty('--menu-max-height', cssValue);
  }

  private updateTranslations(origin: DOMRect, content: DOMRect, style: CSSStyleDeclaration) {
    if (origin && content) {

      const viewport = this.windowRef.nativeWindow?.visualViewport;
      const menuBorderWidth = style?.getPropertyValue('--menu-border-width') || '0px';
      const accumulatedBorderWidth = convertCSSUnitInPixel(menuBorderWidth) * 2;

      const originX = origin.x;
      const contentWidth = content.width + accumulatedBorderWidth;

      let translateX = 0;
      let translateY = 0;
      let correctionLeft = false;
      let correctionRight = false;
      this._fullWidth = false;

      if ((viewport.offsetLeft + viewport.width) <= contentWidth) {
        translateX = 0;
        this._fullWidth = true;

        this.getHostElement().classList.add('app-menu-full-width');

      } else {

        if (viewport.offsetLeft > originX) {
          translateX += ((viewport.offsetLeft - (originX)) * (-1));
          correctionLeft = true;
        }

        if ((viewport.offsetLeft + viewport.width) < (originX + contentWidth)) {
          translateX += ((viewport.offsetLeft + viewport.width) - (originX + contentWidth));
          correctionRight = true;
        }

      }

      translateY += (this.options?.verticalMenuOffset || 0);
      translateX += (this.options?.horizontalMenuOffset || 0);

      this.translateMenu(translateX + 'px', translateY + 'px');

    }
  }

  private translateMenu(x?: string, y?: string) {
    if (x) {
      this.getHostElement()?.style?.setProperty('--menu-translate-x', x);
    }

    if (y) {
      this.getHostElement()?.style?.setProperty('--menu-translate-y', y);
    }
  }

  private setZIndex(num: number) {
    this.getHostElement()?.style?.setProperty('--menu-z-index', (num + ''));
  }

  private setMenuPosition(menuPosition: 'relative' | 'absolute') {
    this.getHostElement()?.style?.setProperty('--menu-position', menuPosition);
  }

  private setMenuDisplay(menuDisplay: 'block' | 'inline') {
    this.getHostElement()?.style?.setProperty('--menu-display', menuDisplay);
  }

}
