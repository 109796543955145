
<div [ngClass]="containerClasses">
  <div class="app-menu-content">
    <ng-container *ngFor="let insert of options?.inserts">
      <ng-container
        [ngComponentOutlet]="insert.component"
        [ngComponentOutletInjector]="insert.__injector"
      ></ng-container>
    </ng-container>
  </div>

</div>
